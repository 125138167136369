.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'] {
  background: var(--md-ref-palette-secondary90);
}

.MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected='true'].Mui-focused,
.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true']:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    var(--md-ref-palette-secondary90);
}
