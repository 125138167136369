@keyframes pulsatingMyDayCard {
  100% {
    /* Larger blur radius */
    box-shadow: 0 0 0 8px #fafcf2, 0 0 20px 8px #f3e359;
  }
  0% {
    /* Smaller blur radius */
    box-shadow: 0 0 0 8px #fafcf2, 0 0 20px 7px #f3e359;
  }
}

.pulsating-my-day-card {
  /* box-shadow: 0 0 5px 0px #fafcf2, 0 5px 5px 0px #fafcf2, 0 -5px 5px 0px #fafcf2,
    5px 0 5px 0px #fafcf2, -5px 0 5px 0px #fafcf2, 0 0 5px 5px #f3e359,
    0 10px 10px 0 #f3e359, 0 -10px 10px 0 #f3e359, 10px 0 10px 0 #f3e359,
    -10px 0 10px 0 #f3e359; */
  opacity: 0.6;
  box-shadow: 0 0 5px 0px var(--md-ref-palette-tertiary90),
    0 5px 5px 0px var(--md-ref-palette-tertiary90),
    0 -5px 5px 0px var(--md-ref-palette-tertiary90),
    5px 0 5px 0px var(--md-ref-palette-tertiary90),
    -5px 0 5px 0px var(--md-ref-palette-tertiary90),
    0 0 5px 5px var(--md-ref-palette-tertiary40),
    0 10px 10px 0 var(--md-ref-palette-tertiary40),
    0 -10px 10px 0 var(--md-ref-palette-tertiary40),
    10px 0 10px 0 var(--md-ref-palette-tertiary40),
    -10px 0 10px 0 var(--md-ref-palette-tertiary40);
  /* animation: pulsatingMyDayCard 200ms infinite alternate; */
}
