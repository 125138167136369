.intercom-page * {
  font-family: Lato, sans-serif;
}

.collection__paper {
  padding: 30px;
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 3%);
}

.collection__photo {
  top: 0;
  left: 0;
  width: 180px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection__photo [fill^='#'],
.collection__photo svg {
  stroke: #818a97;
}

.collection__content {
  padding-left: 150px;
}

.intercom-search-input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 20px;
  border-radius: 4px;
  font-family: Lato, sans-serif;
  padding: 14px 32px 14px 59px;
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%);
  transition: background 0.4s, box-shadow 0.2s;
}
.intercom-search-input.inner-page {
  padding-top: 14px;
  padding-bottom: 14px;
}

.intercom-search-input.active,
.intercom-search-input:active,
.intercom-search-input:focus {
  color: #3a3c4c;
  background: white;
  box-shadow: 0 10px 20px rgb(0 0 0 / 14%);
}

.intercom-search-icon {
  width: 22px;
  height: 22px;
  border: none;
  top: 50%;
  left: 20px;
  outline: none;
  cursor: pointer;
  margin-top: -10px;
  position: absolute;
  background: transparent;
  transition: transform 0.1s linear;
}
.intercom-search-icon:before {
  content: ' ';
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
}
.intercom-search-icon:after {
  content: ' ';
  border-top: 2px solid white;
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 12px;
  height: 1px;
  transition: width 0.1s linear;
  transform-origin: 100% 50%;
  transform: rotate(-135deg);
}

.intercom-search-input.active ~ .intercom-search-icon:before,
.intercom-search-input.active ~ .intercom-search-icon:after {
  border-color: #9fa0a8;
}

.intercom-section {
  padding: 0 0 0 0;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.01);
}

/**
* Toggle the intercom launcher
*/
.hide-intercom-launcher .intercom-namespace,
.hide-intercom-launcher .intercom-lightweight-app {
  opacity: 0;
  visibility: hidden;
}
.full-page-intercom-launcher .intercom-messenger-frame {
  top: 64px !important;
  bottom: 0 !important;
  border-radius: 0 !important;
  max-height: initial !important;
  box-shadow: none !important;
  height: initial !important;
  left: calc(var(--app-drawer-width) + 1px);
  width: calc(100vw - var(--app-drawer-width)) !important;
  max-width: 900px;
  transition: none !important;
  z-index: 10 !important;
  border-right: 1px solid #e6e6e6;
}
.full-page-intercom-launcher .intercom-lightweight-app,
.full-page-intercom-launcher .intercom-launcher-frame,
.full-page-intercom-launcher .intercom-ytk0w4 {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.intercom-borderless-frame {
  display: none !important;
}

/**
  Media-query: max-width: 1023px
*/
@media (max-width: 1023px) {
  .collection__photo {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collection__photo .MuiBox-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collection__photo svg {
    margin: auto;
    display: block;
    width: 30px;
    max-height: 30px;
  }
  .collection__content {
    padding-left: 70px;
  }
}

/**
  Media-query: max-width: 400px
*/
@media (max-width: 400px) {
  .collection__photo {
    top: 30px;
    left: -5px;
    height: initial;
  }
  .collection__content {
    padding-left: 0;
    margin-top: 40px;
  }
  .intercom-page .main-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
